/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
		
		// Search form
    /*    var $searchForm   = $('.navbar-search-form'),
            $submitButton = $searchForm.find('[type="submit"]'),
            $searchInput  = $searchForm.find('[type="text"]');

        var isOpen = false;

        var openSearchForm = function () {
            $searchInput.addClass('open');
            $searchInput.focus();
            isOpen = true;
        };
        var closeSearchForm = function () {
            $searchInput.removeClass('open');

			sOpen = false;
        };
        var submitSearchForm = function () {
            $searchForm.submit();
        };

        $submitButton.click(function (e) {
            e.preventDefault();

            if (isOpen === false) {
                openSearchForm();
                $('.user-login').css('display', 'none');
            }
            else if ($searchInput.val().length > 2) {
               submitSearchForm();
            }
            else {
                closeSearchForm();
                $('.user-login').css('display', 'block');
            }
        });*/
		
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
		
		jQuery('#slider_homepage').slick({
            dots: false,
            infinite: true,
            speed: 500,
            fade: true,
            cssEase: 'linear',
            arrows: false,
            autoplay: true,
            autoplaySpeed: 4000
        });
		
		jQuery('#slider_orchestre').slick({
            dots: false,
            infinite: true,
			slidesToShow: 3,
			slidesToScroll: 3,
            speed: 500,
            fade: false,
            cssEase: 'linear',
            arrows: true,
            autoplay: false,
			responsive: [
				{
				  breakpoint: 1024,
				  settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
					dots: true
				  }
				},
				{
				  breakpoint: 600,
				  settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				  }
				},
				{
				  breakpoint: 480,
				  settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				  }
				}
			  ]
		});
		
		jQuery('#grid').masonry({
			itemSelector: '.grid-item',
			percentPosition: true,
			columnWidth: '.grid-sizer',
			gutter: '.gutter-sizer'
		});
		
		
		
		//;( function( $ ) {
			jQuery('.swipebox').swipebox();
		//} )( jQuery );
		
		
		
		
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'page_template_template_contact': {
      init: function() {
        
		function setGoogleMap() {
			var isDraggable = jQuery(document).width() > 480;
			var myLatlng = new google.maps.LatLng(46.201006, 6.152658);
			var mapCanvas = document.getElementById('map-canvas');
			var mapOptions = {
				center: myLatlng,
				zoom: 18,
				draggable: isDraggable,
				scrollwheel: false,
				streetViewControl: false,
				mapTypeControl: false,
				panControl: !isDraggable,
				panControlOptions: {
					position: google.maps.ControlPosition.RIGHT_BOTTOM
				},
				zoomControlOptions: {
					style: google.maps.ZoomControlStyle.LARGE,
					position: google.maps.ControlPosition.LEFT_CENTER
				},
				mapTypeId: google.maps.MapTypeId.ROADMAP,
				styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}]
			};
			
			var map = new google.maps.Map(mapCanvas, mapOptions);
			var marker = new google.maps.Marker({
				position: myLatlng,
				map: map,
				title: "Musique Municipale de la Ville de Genève",
				/*icon: image,*/
				animation: google.maps.Animation.DROP
			});
			marker.setMap(map);

			return true;
		}
		google.maps.event.addDomListener(window, 'load', setGoogleMap);

      }
    },
	
	
	'page-template-template-membres': {
      init: function() {
		$('#myModal').on('shown.bs.modal', function () {
		  $('#myInput').focus();
		});
      }
    }
	
	
	
	
	
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
